.cards {
    background: #e1ecea !important;
    border-radius: 10px;
}
.cards img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.card-s {
    height: 100%;
  }
.projects .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cnt-project {
    background-color: #e9e9e9 !important;
    margin-bottom: 20px;
    padding: 10px !important;
    margin-top: 90px !important;
    display: flex;
    justify-content: center;
}

.cnt-project button {
    border-radius: 30px;
    padding: 10px 40px;
    background-color: #ffffff;
    border: 1px solid rgb(211, 211, 211);
}
.cnt-project button.bg-danger {
    color: #ff6d6d;
    background-color: white !important;
    border: 1px solid #ff6d6d !important;
    font-weight: 600 !important;
}
.carousel-container {
  position: relative;
  overflow: hidden !important;
}

.slick-dots {
  display: none !important;
}

.slick-prev {
  z-index: 1;
  box-shadow: none;
  border-radius: 10px;
  background-color: #ffffff;
  color: white; 
}

.slick-next {
  z-index: 1;
  box-shadow: none;
  border-radius: 10px;
  background-color: #ffffff;
  color: white; 
}

.slick-prev:before,
.slick-next:before {
  font-size: 24px;
  color: #5a3811da;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  color: #e6994065;
}

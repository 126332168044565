.language {
    background-color: black;
    padding: 10px;
}
.lng-item {
    padding: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lng-item .img {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50px;
}
.lng-item:hover {
    background-color: #dad6d6;
}
.custom_banner {
    padding-top: 150px;
    padding-bottom: 115px;
    position: relative;
    text-align: left !important;
}


.custom_banner .thumb {
  text-align: center !important;
}

.custom_banner .thumb img {
    width: 410px;
    height: 500px;
    margin: auto;
}

.fx-logo {
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.custom_banner .title {
    font-weight: bold;
    font-family: Roboto, sans-serif;
    font-size: 3rem;
    line-height: 1 !important;
}
.custom_banner .subtitle {
    margin: 0px 0px 0.35em;
    font-family: Roboto, sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
}
.custom_banner .tag {
    margin: 0;
    font-family: Poppins, sans-serif;
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 0.35em;
    color: #d4b499;
}

/* src/ImageSlider.css */

  
  .image-slider img {
    position: absolute;
    opacity: 0;
    animation: fadeOut 0s ease-in-out;
    transition: opacity 1s ease-in-out;
  }
  
  .image-slider img.fade-in {
    opacity: 1;
    animation: fadeIn 1s ease-in-out; /* Fade-in animation */
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
.custom_banner .bg_bk {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -11;
  opacity: 0.03;
  background-attachment: fixed;
  background-size: cover;
  background-image: url("/public/assets/bg/2.png");
}
.custom_features {
  
  /* background-image: url('/public/assets/img/shape/50.png'); */
  margin: auto;
}

.custom_features .bg_bk {
  position: absolute;
  left: 150px;
  top: 150px;
  width: 70%;
  height: 70%;
  z-index: -11;
  opacity: 0.2;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/public/assets/img/shape/30.png');
}

.custom_features .tag,
.custom_process .tag,
  .custom_about .tag,
    .custom_team .tag,
      .ourservices .tag {
  color: #b35e0e;
  font-weight: bold;  
  text-transform: uppercase;
}
.custom_about {

}

.custom_about .bg_bk {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
  opacity: 0.5;
  background-size: cover;
  background-position: right;
  background-image: url('/public/assets/img/shape/16.png');
}
.abouticon {
    background-color: #b35e0e !important;
}
.custom_process .actions {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 10px 10px 0;
}
.custom_process .actions .it-process {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}

.head-process-section {
  display: flex !important;
  justify-content: space-between;
  align-items: start;
}
.custom_process .info {
  background: linear-gradient(to bottom right, #fdfdfdda, #ffffff, #ffffff);
  margin-left: -200px;
  padding: 20px;
}
.custom_process .info.bglight {
  background: linear-gradient(to bottom right, #fdfdfdda, #ffffff, #ffffff);
}

.custom_process .info.bgbk {
  background: linear-gradient(to top left, #07204ee3,#07204ee3, #07204ee3, #07204eb4) !important;
  color: white !important;
}

.custom_process .info h3 {
  font-weight: 600;
   background-color: #dd8f1b07;
   padding: 2px 10px;
   font-size: 1.4rem;
  }
.custom_process .info h3 i {
  color: #1bdd93;
}
p {
  font-size: 1.1rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.custom_team {
  position: relative;
}
.custom_team.bgdark {
  background: radial-gradient(circle, #ffffff71, #e1edf06e) !important;
}

.custom_team .bg_bk {
  position: absolute;
  left: 0;
  top: 0;
  width: 70%;
  height: 70%;
  z-index: -11; 
  opacity: 0.9;
  background-size: cover;
  background-image: url('/public/assets/img/shape/16.png');
}

.card_team  {
  background-color: white;
  border: 1px solid whitesmoke;
  border-radius: 5% !important;
  padding: 35px 0 !important;
  border: 1px solid #cfcecd;
}
.card_team  .item,
.card_team  .thumb {
  background-color: none !important;
  background: none !important;
}

.card_team .item .img {
  padding: 5px;
  border: 1px solid #e06e112f;
  width: 200px !important;
  height: 200px !important;
  border-radius: 50% !important;
  margin: auto;
}
.card_team .item .thumb img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50% !important;
}

.card_team .item .media {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
}
.card_team .item .media i {
  font-size: 26px;
  margin: 0 10px;
}
.card_team:hover {
  box-shadow: 1px 1px 7px rgb(209, 208, 208);
}


.ourservices {
}
.ourservices.bgdb {
  background: radial-gradient(circle, #ffffff71, #ffffff);
}

.ourservices .bg_bk {
  position: absolute;
  left: 0;
  top: 0;
  width: 70%;
  height: 70%;
  z-index: -11; 
  opacity: 0.7;
  background-size: cover;
  background-image: url('/public/assets/img/shape/16.png');
}

.ourservices .services {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.ourservices .services .subcontainer {
  padding: 15px 15px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 1.2rem;
}

.ourservices .services .subcontainer.bg-dark {
  background-color: #133347 !important;
  box-shadow: 1px 1px 1px #5d95cc !important;
  color: #ffffff !important;

}
.ourservices .services .subcontainer.bg-light {

  background-color: #f3f9fd !important;
  box-shadow: 1px 1px 1px #5d95cc !important;
  color: #000000 !important;

}


/* partenaires */
.partenaires .bg_bk {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -11; 
  opacity: 0.05;
  background-size: cover;
  background-image: url('/public/assets/img/icon/app/1x.png');
}
.partenaires {
}
.item-tartenaire {
  margin: 10px;
  /* border: 1px solid gray;/. */
}



/* projects section style */
.custom_project.bgdark {
  background: radial-gradient(circle, #fff, rgb(230, 231, 238)) !important;
}
.footer.bg-dark {
  background: #05203b62 !important;
}

.footer.bg-light, .footer-bottom.bg-dark {
  background: #021425 !important;
}

.footer-contants {
  background: #052849 !important;
}

.footer.bg-dark p,
.footer.bg-dark a,
.footer.bg-dark li,
.footer.bg-dark h4 {
  color: white;
}

.custom_project .content {
  /* background-color: #2466a8; */
}


.card_project {
  padding: 0;
}

.card_project {
  position: relative ;
  width: 100% !important;
}

.card_project .item {
  /* padding: 10px; */
  text-align: left !important;
}

.card_project .item .thumb img {
  width: 100% !important;
}

.card_project .info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:linear-gradient(to bottom left, #1921292a, #19212959, #19212975, #192129a9, #192129c0) !important;
  padding: 10px !important;
  color: white;
  border-radius: 10px;
  display: flex !important;
  align-items: last baseline !important;
  justify-content: start !important;
  text-align: left !important;
}

.card_project h4,
.card_project p {
  color: white;
}

.card_project .main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
}

.card_project .footer {
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left !important;
}

.card_project .footer img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  margin-right: 10px;
}
.card_project .footer span {
  font-size: 1rem !important;
  /* padding-top:px; */
  color: white !important;
  text-transform: uppercase;
}

.card_project .main .action {
  background-color: #5661f8;
  color: #ffffff;
  padding: 1px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timing {
  display: flex;
  justify-content: left;
  align-items: center;
}
.timing i {
  padding-right: 7px;
}
.navbar-header .navbar-toggle{
  z-index: 999 !important;
}
.loginLogo img { 
  width: 40%;
}
@media screen and (min-width: 989px) {
  .navbar-toggles {
    display: none !important;
  }
} 
@media screen and (max-width: 989px) {
  .custom_process .info.bgbk {
    background: linear-gradient(to top left, #07204ee3,#07204ee3, #07204ee3, #07204eb4) !important;
    color: white !important;
  }
  .custom_process .info.bglight {
    background: linear-gradient(to top left, #fdfdfde3,#ffffffda, #ffffffd3, #ffffffc2) !important;
  }


  .custom_process .info {
    margin: 0;
    margin-top: -200px;
    padding: 5px;
    padding-top: 120px;
  }
  .custom_process .actions {
    justify-content: left;
    align-items: center;
    padding: 0;
    margin-left: 10px;
    margin-bottom: 15px;
  }
  .custom_process .actions h4 {
    font-size: 13px;
  }
  .custom_features .bg_bk, 
.custom_banner .bg_bk,
.custom_about .bg_bk,
.custom_team .bg_bk,
.ourservices .bg_bk,
  .partenaires .bg_bk {
    left: 0 !important;
  }
}

.logincontainer {
  overflow: hidden !important;
}
.logincontainer .sideimg img {
  width: 100%;
  height: auto;
}

.imgsProjects {
  width: 100% !important;
  height: 700px !important;
}
.imgProject {
  display: 'block' !important;
  height: 100% !important;
  margin: 'auto' !important;
  width: 100% !important;
  background-color: #000000 !important;
}

@media screen and (max-width: 867px) {
  .custom_banner .thumb img {
    width: 100% !important;
    height:  100% !important;
  }
  .custom_banner {
    padding-top: 5px !important;
    padding-bottom: 15px !important;
  }
  .custom_banner.single-pg {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .custom_banner .bg_bk {
    opacity: 0.07;
  }

  .custom_banner .tag {
    font-size: 1.5rem;
    color: #ffd0a4;
    font-family: monospace,sans-serif;
  }

  .custom_banner {
    height: 100%  !important;
  }

  .custom_banner.single-pg {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }

  .loginLogo { 
    display: none !important;
  }

  .fx-logo img {
    width: 40% !important;
    height: 40% !important;
  }

  .custom_process {
    overflow: hidden !important;
  }

  .custom_process .actions {
    padding: 10px 2px 0 !important;
    justify-content: left !important;
    text-align: left !important;
  }

  .custom_process .info {
    padding: 5px !important;
    justify-content: left !important;
    text-align: left !important;
  }

  .custom_process .actions .it-process {
    justify-content: left !important;
    text-align: left !important;
  }

  .imgsProjects {
    width: 100% !important;
    height: 100% !important;
  }

  .menus .menu-item {
    width: 200px !important;
    background-color: transparent !important;
    background: transparent !important;
  }

  .menus .menu-item .link {
    padding: 10px 30px !important;
  }

}


.form {
  background: rgba(255, 255, 255, 0.281) !important;
  padding: 10px;
}
.form input[type] {
  background-color: rgb(255, 255, 255);
}

.menus .link .img {
  width: 30px;
  height: 30px;
  margin: 0 5px;
}
.menus {
  position: relative !important;
}

.menus .menu-item {
  opacity: 0;
  animation: fadeOut 0s ease-in-out;
  transition: opacity 1s ease-in-out;
  background-color: #ffffff !important;
  border-radius: 30px;
  width: 600px !important;
}

.menus .menu-item.fade-in{
  opacity: 1;
  animation: fadeIn 1s ease-in-out; 
}


.menus .menu-item .link {
  background-color: white !important;
  color: black !important;
  padding: 10px 100px;
  width: 100% !important;
  border-radius: 30px;
}
.menus .menu-item .link:hover {
  background-color: rgba(207, 204, 204, 0.63) !important;
  color: #000000af !important;
  margin-left: 10px;

}
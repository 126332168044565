:root {
    --bs-bg-color: #f7f4eb;
    --bs-text-color: #06152cf6;
  }
  
  [data-theme='dark'] {
    --bs-bg-color: #06152cf6;
    --bs-text-color: #ffffff;
  }
  
  .navbars {
    background-color: var(--bs-bg-color) !important;
  }
  .navbarbg {
    background-color: #06152c23 !important;
  }

  
  .adark {
    color: var(--bs-text-color) !important;
  }
  .custom_about {
    background-color: #eeeeee;
  }